import { Side } from '@/compiled_proto/com/celertech/marketdata/api/enums/SideProto';
import cn from 'classnames';

interface DividerProps {
    side?: Side;
    className?: string;
}

const Divider = ({ side, className }: DividerProps) => (
    <hr
        className={cn('w-full border-none h-[1px] m-0 flex-shrink-0', className, {
            ['bg-brand-border']: !className && (side === Side.BUY || !side),
            ['bg-brand-red']: !className && side === Side.SELL
        })}
    />
);

export default Divider;
