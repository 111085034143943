import {
    OrderRoutingOrderEvent,
    OrderServiceClient,
    OrderServiceDefinition
} from '@/compiled_proto/com/celertech/orderrouting/api/notification/OrderServiceProto';
import { forEachAsync } from '@/helpers/asyncIterableHelper';
import { pushNotifications } from '@/helpers/notificationsHelper';
import { BlotterItem } from '@/model/blotters';
import { Logger } from '@/utils/logger';
import { grpc } from '@improbable-eng/grpc-web';
import { createChannel, createClient, Metadata } from 'nice-grpc-web';
import { Middleware } from 'redux';
import { v4 as uuidv4 } from 'uuid';
import { controlClearSubscriptions, controlInitSubscriptions, User } from '../reducers/authSlice';
import { AppDispatch, RootState } from '../store';

let subscriptionId: any = null;
let orderStatusSubscription: any = null;
let notificationBuffer: OrderRoutingOrderEvent[] = [];
let isBuffering = false;
const MAX_BATCH_SIZE = 50;

const setupOrderStatusSubscription = async (credentials: User, dispatch: AppDispatch, store: any) => {
    orderStatusSubscription = orderServiceClientWs.updateOnAllFxOrderSnapshots(
        {},
        { metadata: Metadata({ 'authorization-token': credentials.authToken }) }
    );
    // Create a dictionary to store the previous event information for each order

    const currentSubscriptionId = uuidv4();
    subscriptionId = currentSubscriptionId;
    forEachAsync(
        orderStatusSubscription,
        (orderUpdate: OrderRoutingOrderEvent) => {
            const state: RootState = store.getState();
            const previousEvents: BlotterItem[] = state.blotter.blotterOrders;

            notificationBuffer.unshift(orderUpdate);
            if (notificationBuffer.length >= MAX_BATCH_SIZE) {
                pushNotifications({ latestEvents: notificationBuffer, previousEvents, state, dispatch });
                notificationBuffer = [];
            } else if (!isBuffering) {
                isBuffering = true;
                setTimeout(() => {
                    pushNotifications({ latestEvents: notificationBuffer, previousEvents, state, dispatch });
                    notificationBuffer = [];
                    isBuffering = false;
                }, 1000);
            }
        },
        () => subscriptionId !== currentSubscriptionId,
        `OrderStatusMiddleware`
    ).catch((error) => console.error({ 'OrderStatusMiddleware subscription': error }));
};

const wsChannelUrl = window.config.integration.celertech.websocket;
const wsChannel = createChannel(wsChannelUrl, grpc.WebsocketTransport());

// Subscribe to order updates
// wss://gce-uat-lon-webtrader-fx.celer-tech.com/com.celertech.orderrouting.api.order.OrderService/updateOnAllFxOrderSnapshots
const orderServiceClientWs: OrderServiceClient = createClient(OrderServiceDefinition, wsChannel);

const OrderStatusMiddleware: Middleware = (store) => (next) => (action) => {
    if (controlClearSubscriptions.match(action)) {
        Logger({ title: `OrderStatusMiddleware: Clear Subscriptions`, callback: () => {} });
        subscriptionId = null;
        orderStatusSubscription = null;
    } else if (controlInitSubscriptions.match(action)) {
        Logger({ title: `OrderStatusMiddleware: Initialise Subscriptions`, callback: () => {} });
        const dispatch: AppDispatch = store.dispatch;
        const state: RootState = store.getState();
        const creds = state.auth.user;
        // If not already listening, setup subscriptions
        if (!orderStatusSubscription && creds) {
            setupOrderStatusSubscription(creds, dispatch, store);
        }
    }

    // Pass on to next middlewares in line
    next(action);
};

export default OrderStatusMiddleware;
