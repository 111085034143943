import { getAssetName } from '@/utils/symbolMapping';
import { ToastOptions } from 'react-hot-toast';
import { Side } from '../compiled_proto/com/celertech/marketdata/api/enums/SideProto';
import { ExecutionType } from '../compiled_proto/com/celertech/orderrouting/api/enums/ExecutionTypeProto';
import { OrderType } from '../compiled_proto/com/celertech/orderrouting/api/enums/OrderTypeProto';
import { TimeInForceType } from '../compiled_proto/com/celertech/orderrouting/api/enums/TimeInForceTypeProto';
import { FxOrderSnapshotDownstreamEvent } from '../compiled_proto/com/celertech/orderrouting/api/order/DownstreamOrderProto';
import { BlotterItem } from '../model/blotters';
import { capitalize, capitalizeAll, getQtyFormatter } from '../utils/format';
import { toastSuccess } from '../utils/hooks/useToast';
import { titlePrice } from './orderStatusHelper';
// import { getOtherCurrency } from './currencyHelper';

export interface OrderEvent {
    orderId: string;
    price: number;
    qty: number;
}

export const toastExecutionType = (
    previousEvents: BlotterItem[],
    event: FxOrderSnapshotDownstreamEvent,
    toastConfig: ToastOptions
) => {
    const {
        // securityId,
        qty,
        price,
        stopPx,
        orderId,
        id,
        currency,
        // leg,
        securityCode,
        orderType,
        orderStatus,
        executionType,
        side: orderSide,
        timeInForce,
        orderMetadata
        // lastQty,
        // cumQty,
        // leavesQty
    } = event;

    const previousEvent = previousEvents.find((event) => event.orderId === orderId);

    const type = OrderType[orderType] as keyof typeof OrderType;
    const side = Side[orderSide] as keyof typeof Side;
    const oppSide = Side[orderSide === 1 ? 2 : 1] as keyof typeof Side;
    const execution_type = ExecutionType[executionType] as keyof typeof ExecutionType;

    const text = {
        orderType: `${capitalizeAll(type)} Order`,
        side: capitalize(side),
        oppSide: capitalize(oppSide),
        tif: TimeInForceType[timeInForce].toString(),
        titlePrice: titlePrice(
            orderType,
            previousEvent?.price || 0,
            previousEvent?.stopPrice || 0,
            orderMetadata.find((meta) => meta.key === 'SLIPPAGE')?.value || 0
        )
    };

    const formatQty = getQtyFormatter();

    const fmtPrice = formatQty.format(price);
    const fmtQuantity = formatQty.format(qty);
    const fmtStopPrice = formatQty.format(stopPx);
    const fmtPrevQuantity = formatQty.format(previousEvent?.qty || 0);
    // const fmtLegPrice = formatQty.format(leg[0]?.price);
    // const fmtFilledAmount = formatQty.format(lastQty);
    // const fmtRemainingAmount = formatQty.format(leavesQty);
    // const fmtAccumulatedAmount = formatQty.format(cumQty);

    const ccy = getAssetName(currency);
    const [ccy1, ccy2] = securityCode.split('/');
    const ccy2Order = currency === ccy2;

    let position = '';
    if (ccy2Order) position = `${ccy2} vs ${ccy1}`;
    else position = `${ccy1} vs ${ccy2}`;

    const title = `${text.orderType} to ${text.side} ${fmtPrevQuantity} ${position} ${text.titlePrice}`;

    const showPrice = () => {
        switch (orderType) {
            case OrderType.LIMIT:
                return price !== previousEvent?.price && `Amended Price: ${fmtPrice}\n`;
            case OrderType.STOP_LIMIT:
                return stopPx !== previousEvent?.stopPrice && `Amended Price: ${fmtStopPrice}\n`;
            case OrderType.STOP_MARKET:
                return stopPx !== previousEvent?.stopPrice && `Amended Price: ${fmtStopPrice}\n`;
            default:
                return '';
        }
    };

    switch (execution_type) {
        case 'REPLACED':
            {
                let body = '';

                const amendedPrice = showPrice();
                if (amendedPrice) body += amendedPrice;

                if (qty !== previousEvent?.qty) body += `Amended Amount: ${fmtQuantity} ${ccy}\n`;
                body += `OrderId: ${orderId}\n`;
                body += `Exec Id: ${id}`;

                toastSuccess({ body, title: `${title} Amended`, toastConfig });
            }
            break;
        default:
            break;
    }
};

export const getExecutionTypeToastConfig = (previousEvents: BlotterItem[], event: FxOrderSnapshotDownstreamEvent) => {
    const {
        // securityId,
        qty,
        price,
        stopPx,
        orderId,
        id,
        currency,
        // leg,
        securityCode,
        orderType,
        orderStatus,
        executionType,
        side: orderSide,
        timeInForce,
        orderMetadata
        // lastQty,
        // cumQty,
        // leavesQty
    } = event;

    const previousEvent = previousEvents.find((event) => event.orderId === orderId);

    const type = OrderType[orderType] as keyof typeof OrderType;
    const side = Side[orderSide] as keyof typeof Side;
    const oppSide = Side[orderSide === 1 ? 2 : 1] as keyof typeof Side;
    const execution_type = ExecutionType[executionType] as keyof typeof ExecutionType;

    const text = {
        orderType: `${capitalizeAll(type)} Order`,
        side: capitalize(side),
        oppSide: capitalize(oppSide),
        tif: TimeInForceType[timeInForce].toString(),
        titlePrice: titlePrice(
            orderType,
            previousEvent?.price || 0,
            previousEvent?.stopPrice || 0,
            orderMetadata.find((meta) => meta.key === 'SLIPPAGE')?.value || 0
        )
    };

    const formatQty = getQtyFormatter();

    const fmtPrice = formatQty.format(price);
    const fmtQuantity = formatQty.format(qty);
    const fmtStopPrice = formatQty.format(stopPx);
    const fmtPrevQuantity = formatQty.format(previousEvent?.qty || 0);
    // const fmtLegPrice = formatQty.format(leg[0]?.price);
    // const fmtFilledAmount = formatQty.format(lastQty);
    // const fmtRemainingAmount = formatQty.format(leavesQty);
    // const fmtAccumulatedAmount = formatQty.format(cumQty);

    const ccy = getAssetName(currency);
    const [ccy1, ccy2] = securityCode.split('/');
    const ccy2Order = currency === ccy2;

    let position = '';
    if (ccy2Order) position = `${ccy2} vs ${ccy1}`;
    else position = `${ccy1} vs ${ccy2}`;

    const title = `${text.orderType} to ${text.side} ${fmtPrevQuantity} ${position} ${text.titlePrice}`;

    const showPrice = () => {
        switch (orderType) {
            case OrderType.LIMIT:
                return price !== previousEvent?.price && `Amended Price: ${fmtPrice}\n`;
            case OrderType.STOP_LIMIT:
                return stopPx !== previousEvent?.stopPrice && `Amended Price: ${fmtStopPrice}\n`;
            case OrderType.STOP_MARKET:
                return stopPx !== previousEvent?.stopPrice && `Amended Price: ${fmtStopPrice}\n`;
            default:
                return '';
        }
    };

    switch (execution_type) {
        case 'REPLACED': {
            let body = '';

            const amendedPrice = showPrice();
            if (amendedPrice) body += amendedPrice;

            if (qty !== previousEvent?.qty) body += `Amended Amount: ${fmtQuantity} ${ccy}\n`;
            body += `OrderId: ${orderId}\n`;
            body += `Exec Id: ${id}`;

            return { type: 'success', body, title: `${title} Amended`, created_at: new Date() };
        }
        default:
            break;
    }
};
