/* eslint-disable */
import { CallContext, CallOptions } from 'nice-grpc-common';
import { TransactionDownstreamEvent } from './generalledger/DownstreamGeneralLedgerProto';
import { SubLedgerSnapshotDownstreamEvent } from './subledger/DownstreamSubLedgerProto';
import { Empty } from '../../../../google/protobuf/empty';
import { UpdateOnTransactionCreationRequest } from './datastream/UpstreamDataStreamProto';
import _m0 from 'protobufjs/minimal';

export const protobufPackage = 'com.celertech.piggybank.api';

export interface PiggyBankUpdateEvent {
    transactionDownstreamEvent?: TransactionDownstreamEvent | undefined;
    subLedgerSnapshotDownstreamEvent?: SubLedgerSnapshotDownstreamEvent | undefined;
    isHeartBeat: boolean;
}

export interface SubscribeForPiggyBankRequest {
    account: string;
}

export interface SubscribeToPositionByPairTables {
    clientRequestId: string;
    accountCode: string;
}

export interface FindAllPositionsByPairForAccount {
    clientRequestId: string;
    accountCode: string;
}

export interface PositionByPairTableRow {
    settlementDate: string;
    securityId: string;
    netBasePosition: number;
    netTermPosition: number;
}

export interface PositionByPairTable {
    row: PositionByPairTableRow[];
}

function createBasePiggyBankUpdateEvent(): PiggyBankUpdateEvent {
    return { transactionDownstreamEvent: undefined, subLedgerSnapshotDownstreamEvent: undefined, isHeartBeat: false };
}

export const PiggyBankUpdateEvent = {
    encode(message: PiggyBankUpdateEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.transactionDownstreamEvent !== undefined) {
            TransactionDownstreamEvent.encode(message.transactionDownstreamEvent, writer.uint32(10).fork()).ldelim();
        }
        if (message.subLedgerSnapshotDownstreamEvent !== undefined) {
            SubLedgerSnapshotDownstreamEvent.encode(
                message.subLedgerSnapshotDownstreamEvent,
                writer.uint32(18).fork()
            ).ldelim();
        }
        if (message.isHeartBeat === true) {
            writer.uint32(24).bool(message.isHeartBeat);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): PiggyBankUpdateEvent {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePiggyBankUpdateEvent();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.transactionDownstreamEvent = TransactionDownstreamEvent.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.subLedgerSnapshotDownstreamEvent = SubLedgerSnapshotDownstreamEvent.decode(
                        reader,
                        reader.uint32()
                    );
                    break;
                case 3:
                    message.isHeartBeat = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<PiggyBankUpdateEvent>): PiggyBankUpdateEvent {
        const message = createBasePiggyBankUpdateEvent();
        message.transactionDownstreamEvent =
            object.transactionDownstreamEvent !== undefined && object.transactionDownstreamEvent !== null
                ? TransactionDownstreamEvent.fromPartial(object.transactionDownstreamEvent)
                : undefined;
        message.subLedgerSnapshotDownstreamEvent =
            object.subLedgerSnapshotDownstreamEvent !== undefined && object.subLedgerSnapshotDownstreamEvent !== null
                ? SubLedgerSnapshotDownstreamEvent.fromPartial(object.subLedgerSnapshotDownstreamEvent)
                : undefined;
        message.isHeartBeat = object.isHeartBeat ?? false;
        return message;
    }
};

function createBaseSubscribeForPiggyBankRequest(): SubscribeForPiggyBankRequest {
    return { account: '' };
}

export const SubscribeForPiggyBankRequest = {
    encode(message: SubscribeForPiggyBankRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.account !== '') {
            writer.uint32(10).string(message.account);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): SubscribeForPiggyBankRequest {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSubscribeForPiggyBankRequest();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.account = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<SubscribeForPiggyBankRequest>): SubscribeForPiggyBankRequest {
        const message = createBaseSubscribeForPiggyBankRequest();
        message.account = object.account ?? '';
        return message;
    }
};

function createBaseSubscribeToPositionByPairTables(): SubscribeToPositionByPairTables {
    return { clientRequestId: '', accountCode: '' };
}

export const SubscribeToPositionByPairTables = {
    encode(message: SubscribeToPositionByPairTables, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.accountCode !== '') {
            writer.uint32(18).string(message.accountCode);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): SubscribeToPositionByPairTables {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseSubscribeToPositionByPairTables();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.accountCode = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<SubscribeToPositionByPairTables>): SubscribeToPositionByPairTables {
        const message = createBaseSubscribeToPositionByPairTables();
        message.clientRequestId = object.clientRequestId ?? '';
        message.accountCode = object.accountCode ?? '';
        return message;
    }
};

function createBaseFindAllPositionsByPairForAccount(): FindAllPositionsByPairForAccount {
    return { clientRequestId: '', accountCode: '' };
}

export const FindAllPositionsByPairForAccount = {
    encode(message: FindAllPositionsByPairForAccount, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.clientRequestId !== '') {
            writer.uint32(10).string(message.clientRequestId);
        }
        if (message.accountCode !== '') {
            writer.uint32(18).string(message.accountCode);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): FindAllPositionsByPairForAccount {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseFindAllPositionsByPairForAccount();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.clientRequestId = reader.string();
                    break;
                case 2:
                    message.accountCode = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<FindAllPositionsByPairForAccount>): FindAllPositionsByPairForAccount {
        const message = createBaseFindAllPositionsByPairForAccount();
        message.clientRequestId = object.clientRequestId ?? '';
        message.accountCode = object.accountCode ?? '';
        return message;
    }
};

function createBasePositionByPairTableRow(): PositionByPairTableRow {
    return { settlementDate: '', securityId: '', netBasePosition: 0, netTermPosition: 0 };
}

export const PositionByPairTableRow = {
    encode(message: PositionByPairTableRow, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.settlementDate !== '') {
            writer.uint32(10).string(message.settlementDate);
        }
        if (message.securityId !== '') {
            writer.uint32(18).string(message.securityId);
        }
        if (message.netBasePosition !== 0) {
            writer.uint32(25).double(message.netBasePosition);
        }
        if (message.netTermPosition !== 0) {
            writer.uint32(33).double(message.netTermPosition);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): PositionByPairTableRow {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePositionByPairTableRow();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.settlementDate = reader.string();
                    break;
                case 2:
                    message.securityId = reader.string();
                    break;
                case 3:
                    message.netBasePosition = reader.double();
                    break;
                case 4:
                    message.netTermPosition = reader.double();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<PositionByPairTableRow>): PositionByPairTableRow {
        const message = createBasePositionByPairTableRow();
        message.settlementDate = object.settlementDate ?? '';
        message.securityId = object.securityId ?? '';
        message.netBasePosition = object.netBasePosition ?? 0;
        message.netTermPosition = object.netTermPosition ?? 0;
        return message;
    }
};

function createBasePositionByPairTable(): PositionByPairTable {
    return { row: [] };
}

export const PositionByPairTable = {
    encode(message: PositionByPairTable, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        for (const v of message.row) {
            PositionByPairTableRow.encode(v!, writer.uint32(10).fork()).ldelim();
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): PositionByPairTable {
        const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBasePositionByPairTable();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.row.push(PositionByPairTableRow.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromPartial(object: DeepPartial<PositionByPairTable>): PositionByPairTable {
        const message = createBasePositionByPairTable();
        message.row = object.row?.map((e) => PositionByPairTableRow.fromPartial(e)) || [];
        return message;
    }
};

export type PiggyBankServiceDefinition = typeof PiggyBankServiceDefinition;
export const PiggyBankServiceDefinition = {
    name: 'PiggyBankService',
    fullName: 'com.celertech.piggybank.api.PiggyBankService',
    methods: {
        subscribeForCurrencyPositions: {
            name: 'subscribeForCurrencyPositions',
            requestType: Empty,
            requestStream: false,
            responseType: PiggyBankUpdateEvent,
            responseStream: true,
            options: {}
        },
        findAllCurrencyPositionsByAccount: {
            name: 'findAllCurrencyPositionsByAccount',
            requestType: SubscribeForPiggyBankRequest,
            requestStream: false,
            responseType: PiggyBankUpdateEvent,
            responseStream: true,
            options: {}
        },
        updateOnTransactionCreationRequest: {
            name: 'updateOnTransactionCreationRequest',
            requestType: UpdateOnTransactionCreationRequest,
            requestStream: false,
            responseType: TransactionDownstreamEvent,
            responseStream: true,
            options: {}
        },
        findAllPositionsByPairForAccount: {
            name: 'findAllPositionsByPairForAccount',
            requestType: FindAllPositionsByPairForAccount,
            requestStream: false,
            responseType: PositionByPairTable,
            responseStream: false,
            options: {}
        },
        subscribeToPositionByPairTablesOnTransactionCreation: {
            name: 'subscribeToPositionByPairTablesOnTransactionCreation',
            requestType: SubscribeToPositionByPairTables,
            requestStream: false,
            responseType: PositionByPairTable,
            responseStream: true,
            options: {}
        }
    }
} as const;

export interface PiggyBankServiceServiceImplementation<CallContextExt = {}> {
    subscribeForCurrencyPositions(
        request: Empty,
        context: CallContext & CallContextExt
    ): ServerStreamingMethodResult<DeepPartial<PiggyBankUpdateEvent>>;
    findAllCurrencyPositionsByAccount(
        request: SubscribeForPiggyBankRequest,
        context: CallContext & CallContextExt
    ): ServerStreamingMethodResult<DeepPartial<PiggyBankUpdateEvent>>;
    updateOnTransactionCreationRequest(
        request: UpdateOnTransactionCreationRequest,
        context: CallContext & CallContextExt
    ): ServerStreamingMethodResult<DeepPartial<TransactionDownstreamEvent>>;
    findAllPositionsByPairForAccount(
        request: FindAllPositionsByPairForAccount,
        context: CallContext & CallContextExt
    ): Promise<DeepPartial<PositionByPairTable>>;
    subscribeToPositionByPairTablesOnTransactionCreation(
        request: SubscribeToPositionByPairTables,
        context: CallContext & CallContextExt
    ): ServerStreamingMethodResult<DeepPartial<PositionByPairTable>>;
}

export interface PiggyBankServiceClient<CallOptionsExt = {}> {
    subscribeForCurrencyPositions(
        request: DeepPartial<Empty>,
        options?: CallOptions & CallOptionsExt
    ): AsyncIterable<PiggyBankUpdateEvent>;
    findAllCurrencyPositionsByAccount(
        request: DeepPartial<SubscribeForPiggyBankRequest>,
        options?: CallOptions & CallOptionsExt
    ): AsyncIterable<PiggyBankUpdateEvent>;
    updateOnTransactionCreationRequest(
        request: DeepPartial<UpdateOnTransactionCreationRequest>,
        options?: CallOptions & CallOptionsExt
    ): AsyncIterable<TransactionDownstreamEvent>;
    findAllPositionsByPairForAccount(
        request: DeepPartial<FindAllPositionsByPairForAccount>,
        options?: CallOptions & CallOptionsExt
    ): Promise<PositionByPairTable>;
    subscribeToPositionByPairTablesOnTransactionCreation(
        request: DeepPartial<SubscribeToPositionByPairTables>,
        options?: CallOptions & CallOptionsExt
    ): AsyncIterable<PositionByPairTable>;
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
    ? T
    : T extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : T extends {}
    ? { [K in keyof T]?: DeepPartial<T[K]> }
    : Partial<T>;

export type ServerStreamingMethodResult<Response> = {
    [Symbol.asyncIterator](): AsyncIterator<Response, void>;
};
