import Divider from '@/components/common/Divider';
import { switchTab as switchXplorSpotTab } from '@/components/layout/MobileNavbar';
import { switchTab as switchXplorTraderTab } from '@/components/layout/trader/TraderMobileNavbar';
import Modal, { ModalHeader, ModalTitle } from '@/components/modal/Modal';
import { NotificationIcon } from '@/components/modules/Notifications';
import { useAppDispatch, useAppSelector } from '@/state/hooks';
import { selectCredentials } from '@/state/reducers/authSlice';
import {
    selectNotifications,
    selectNotificationsHideTillNextLogin,
    selectNotificationsVisited,
    setHideNotificationsTillNextLogin,
    setNotificationsVisited
} from '@/state/reducers/notificationSlice';
import { selectGeneralSettings } from '@/state/reducers/settingSlice';
import { formatNotification, formatUTCNotification } from '@/utils/format';
import { useDebouncedValue } from '@/utils/hooks/useDebouncedValue';
import { useDidUpdate } from '@/utils/hooks/useDidUpdate';
import { useDisclosure } from '@/utils/hooks/useDisclosure';
import useScreenSizes from '@/utils/hooks/useScreenSizes';
import useXplorSpotLayout from '@/utils/hooks/useXplorSpotLayout';
import useXplorTraderLayout from '@/utils/hooks/useXplorTraderLayout';
import cn from 'classnames';
import { differenceInMinutes } from 'date-fns';
import { now } from 'lodash';
import { useMemo, useState } from 'react';

interface MobileNotificationsModal {}

const MobileNotificationsModal = (props: MobileNotificationsModal) => {
    // const { opened, handlers, type, title, body, created_at } = props;
    const dispatch = useAppDispatch();

    const credentials = useAppSelector(selectCredentials);
    const notifications = useAppSelector(selectNotifications);
    const generalSettings = useAppSelector(selectGeneralSettings);
    const notificationsVisited = useAppSelector(selectNotificationsVisited);
    const hideNotificationsTillNextLogin = useAppSelector(selectNotificationsHideTillNextLogin);
    const [debouncedNotifications] = useDebouncedValue(notifications, 1000);

    const screen = useScreenSizes();
    const { layout: spotLayout } = useXplorSpotLayout(screen);
    const { layout: traderLayout } = useXplorTraderLayout(screen);

    const [opened, handlers] = useDisclosure(false);

    const [isChecked, setIsChecked] = useState(hideNotificationsTillNextLogin);

    const shouldOpen = useMemo(
        () => (screen.mobile || screen.tablet) && opened && !hideNotificationsTillNextLogin,
        [screen.mobile, screen.tablet, opened, hideNotificationsTillNextLogin]
    );

    const latestNotifications = useMemo(() => {
        const currentTime = now();
        const latest = debouncedNotifications
            .filter((notification) => {
                const minutesDifference = differenceInMinutes(currentTime, notification.created_at);
                return minutesDifference <= 3;
            })
            .slice(0, 10);
        return latest;
    }, [debouncedNotifications]);

    useDidUpdate(() => {
        if (!notificationsVisited && latestNotifications.length > 0) handlers.open();
    }, [latestNotifications]);

    return (
        <Modal opened={!!shouldOpen} handlers={handlers} className="h-2/3 sm:h-full" size="max-w-md sm:max-w-lg">
            <ModalHeader>
                <ModalTitle>Notifications</ModalTitle>
                {hideNotificationsTillNextLogin && (
                    <div className={cn('text-xs cursor-pointer text-neutral-400')}>
                        <span>Pop-ups disabled until next login</span>
                    </div>
                )}
            </ModalHeader>
            <Divider />
            <div className="flex-1 basis-0 h-full overflow-y-scroll w-full bg-brand-background-dark text-neutral-200">
                {latestNotifications.map(({ title, body, type, created_at }, id) => {
                    return (
                        <div
                            key={id}
                            className="flex gap-3 bg-brand-background-dark text-neutral-200 p-2 sm:px-4 border-b border-neutral-700">
                            <span className="mt-1">
                                <NotificationIcon type={type} />
                            </span>
                            <div className="flex flex-col w-full text-xs xs:text-sm sm:text-base">
                                <div className="flex w-full justify-between gap-2 relative">
                                    <div className="font-semibold line-clamp-2">{title}</div>
                                    <div className="text-neutral-400 whitespace-nowrap flex flex-col items-end">
                                        <span>
                                            {generalSettings.timezone.value === 'UTC'
                                                ? formatUTCNotification(created_at)
                                                : formatNotification(created_at)}{' '}
                                            ( {generalSettings.timezone.label.replace(' Timezone', '')} )
                                        </span>
                                    </div>
                                </div>
                                <div className="text-neutral-400 whitespace-pre-wrap text-xs xs:text-sm">{body}</div>
                            </div>
                        </div>
                    );
                })}
                <div className="text-neutral-400 text-2xs xs:text-xs sm:text-sm text-center p-2 py-4 sm:px-4">
                    <div>
                        Showing {latestNotifications.length >= 10 && '10'} latest notifications from 3 minutes ago
                    </div>
                </div>
            </div>
            <Divider />
            <div
                className={cn('text-neutral-200 flex flex-wrap-reverse items-center p-2 sm:p-4 text-sm gap-2', {
                    ['justify-between']: !hideNotificationsTillNextLogin,
                    ['justify-end']: hideNotificationsTillNextLogin
                })}>
                {!hideNotificationsTillNextLogin && (
                    <div className="flex items-start gap-2">
                        <input
                            id="hide-notifications-checkbox"
                            type="checkbox"
                            style={{
                                height: 12,
                                width: 12
                            }}
                            className={cn('cursor-pointer mt-0.5 checked:accent-brand-primary-dark bg-neutral-400')}
                            checked={isChecked}
                            onChange={() => setIsChecked(!isChecked)}
                        />
                        <label
                            htmlFor="hide-notifications-checkbox"
                            className={cn('text-xs cursor-pointer', {
                                ['text-brand-primary-light']: isChecked,
                                ['text-neutral-200']: !isChecked
                            })}>
                            <span>Hide notification pop-ups until next login</span>
                            <br />
                            <span className="text-2xs text-neutral-400">
                                Notifications are still available by clicking the bell icon
                            </span>
                        </label>
                    </div>
                )}
                <div
                    className={cn('flex gap-2', {
                        ['w-full']: screen.mobile
                    })}>
                    <button
                        className={cn('rounded-md p-2 px-4 bg-neutral-600 hover:bg-neutral-500', {
                            ['w-full']: !credentials
                        })}
                        onClick={() => {
                            handlers.close();
                            if (isChecked) dispatch(setHideNotificationsTillNextLogin(true));
                            else dispatch(setHideNotificationsTillNextLogin(false));
                        }}>
                        Dismiss
                    </button>
                    {credentials && ['/', '/trader'].includes(location.pathname) && (
                        <button
                            className={cn('rounded-md p-2 px-4 bg-brand-primary hover:bg-brand-primary-light', {
                                ['w-full']: screen.mobile
                            })}
                            onClick={() => {
                                handlers.close();
                                dispatch(setNotificationsVisited(true));
                                if (location.pathname === '/' && spotLayout.first !== 'Notifications')
                                    switchXplorSpotTab(screen, spotLayout, 'Notifications', dispatch);
                                if (location.pathname === '/trader' && traderLayout.second !== 'Notifications')
                                    switchXplorTraderTab(screen, traderLayout, 'Notifications', dispatch);
                            }}>
                            View All
                        </button>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default MobileNotificationsModal;
