import CurrencySwitch from '@/components/common/CurrencySwitch';
import InstrumentInfo, { formConfig } from '@/components/common/InstrumentInfo';
import { PairMap } from '@/state/reducers/tickerSlice';
import { UseOrderExecutionReturn } from '@/utils/hooks/useOrderExecution';
import { UseOrderExecutionTraderReturn } from '@/utils/hooks/useOrderExecutionTrader';

type OrderExecutionReturn = Omit<UseOrderExecutionReturn, 'activePair'> &
    Omit<UseOrderExecutionTraderReturn, 'activeTicker'>;

interface FormCurrencyProps extends OrderExecutionReturn {
    currency: string;
    setCurrency: (currency: string) => void;
    activePair?: PairMap;
    activeTicker?: PairMap;
    mode?: 'Spot' | 'Trader';
}

const FormCurrency = (props: FormCurrencyProps) => {
    const {
        currency,
        instrument,
        ccy1Config,
        ccy2Config,
        setCurrency,
        activePair,
        activeTicker,
        orderMessage,
        mode = 'Spot'
    } = props;
    const { ccy2_enabled } = instrument;

    return (
        <div className="flex justify-between items-center gap-1 flex-wrap">
            <div className="flex items-center gap-1 text-sm md:text-base">
                <span className="whitespace-nowrap">{orderMessage}</span>
                <InstrumentInfo market={mode === 'Spot' ? activePair : activeTicker} {...formConfig} />
            </div>
            <CurrencySwitch
                value={currency}
                instrument={instrument}
                baseCurrency={ccy1Config}
                quoteCurrency={ccy2Config}
                disabled={!ccy2_enabled}
                onChange={setCurrency}
            />
        </div>
    );
};

export default FormCurrency;
