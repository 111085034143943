import Divider from '@/components/common/Divider';
import Modal, { ModalHeader, ModalTitle } from '@/components/modal/Modal';
import { NotificationIcon } from '@/components/modules/Notifications';
import { useAppDispatch, useAppSelector } from '@/state/hooks';
import { selectCredentials } from '@/state/reducers/authSlice';
import {
    closeNotifications,
    openNotifications,
    selectNotifications,
    selectNotificationsHideTillNextLogin,
    selectNotificationsOpened,
    selectNotificationsVisited,
    setHideNotificationsTillNextLogin,
    toggleNotifications
} from '@/state/reducers/notificationSlice';
import { selectGeneralSettings, selectNotificationSettings } from '@/state/reducers/settingSlice';
import { formatNotification, formatUTCNotification } from '@/utils/format';
import { useDidUpdate } from '@/utils/hooks/useDidUpdate';
import { parseNotificationSettings } from '@/utils/hooks/useToast';
import cn from 'classnames';
import { useMemo, useState } from 'react';

// export enum NotificationsTab {
//     'Show All' = 0,
//     'Successful' = 1,
//     'Failed' = 2
// }

// export const notificationsTabs: { label: keyof typeof NotificationsTab; value: NotificationsTab }[] = [
//     { label: 'Show All', value: NotificationsTab['Show All'] },
//     { label: 'Successful', value: NotificationsTab['Successful'] },
//     { label: 'Failed', value: NotificationsTab['Failed'] }
// ];

interface NotificationsModal {}

const DesktopNotificationsModal = (props: NotificationsModal) => {
    const dispatch = useAppDispatch();

    const credentials = useAppSelector(selectCredentials);
    const notifications = useAppSelector(selectNotifications);
    const notificationsVisited = useAppSelector(selectNotificationsVisited);
    const notificationSettings = useAppSelector(selectNotificationSettings);
    const hideNotificationsTillNextLogin = useAppSelector(selectNotificationsHideTillNextLogin);
    const generalSettings = useAppSelector(selectGeneralSettings);
    const opened = useAppSelector(selectNotificationsOpened);

    const defaultConfig = parseNotificationSettings(notificationSettings);

    const [showNotificationsQuantity, setShowNotificationsQuantity] = useState(10);
    const [isChecked, setIsChecked] = useState(hideNotificationsTillNextLogin);
    // const [notificationsTab, setNotificationsTab] = useState(NotificationsTab['Show All']);

    const handlers: any = useMemo(
        () => ({
            open: () => dispatch(openNotifications()),
            close: () => dispatch(closeNotifications()),
            toggle: () => dispatch(toggleNotifications())
        }),
        [opened]
    );

    useDidUpdate(() => {
        if (!notificationsVisited && notifications.length > 0 && defaultConfig.show && !hideNotificationsTillNextLogin)
            handlers.open();
    }, [notifications]);

    return (
        <Modal opened={opened} handlers={handlers} className="lg:!h-[670px]" size="max-w-xl">
            <ModalHeader>
                <ModalTitle>Notifications</ModalTitle>
                {hideNotificationsTillNextLogin && (
                    <div className={cn('text-xs cursor-pointer text-neutral-400')}>
                        <span>Pop-ups disabled until next login</span>
                    </div>
                )}
            </ModalHeader>
            <Divider />
            {notifications.length === 0 ? (
                <div className="flex flex-1 basis-0 items-center justify-center text-neutral-500">No notifications</div>
            ) : (
                <div className="flex-1 basis-0 h-full overflow-y-scroll w-full bg-brand-background-dark text-neutral-200">
                    {/* <TabFilters.Main className="border-b border-neutral-700">
                        <TabFilters.Filter
                            className="!px-4 !h-[32px]"
                            selectedFilter={notificationsTab}
                            setSelectedFilter={setNotificationsTab}
                            filter={notificationsTabs[0]}
                        />
                    </TabFilters.Main> */}
                    {notifications.slice(0, showNotificationsQuantity).map(({ title, body, type, created_at }, id) => {
                        return (
                            <div
                                key={id}
                                className="flex gap-3 bg-brand-background-dark text-neutral-200 p-2 sm:px-4 border-b border-neutral-700">
                                <span className="mt-1">
                                    <NotificationIcon type={type} />
                                </span>
                                <div className="flex flex-col w-full text-sm sm:text-base">
                                    <div className="flex w-full justify-between gap-2">
                                        <div className="font-semibold line-clamp-2">{title}</div>
                                        <div className="text-sm sm:text-base text-neutral-400 whitespace-nowrap flex flex-col items-end">
                                            <span>
                                                {generalSettings.timezone.value === 'UTC'
                                                    ? formatUTCNotification(created_at)
                                                    : formatNotification(created_at)}{' '}
                                                ( {generalSettings.timezone.label.replace(' Timezone', '')} )
                                            </span>
                                        </div>
                                    </div>
                                    <div className="text-neutral-400 whitespace-pre-wrap text-sm sm:text-base">
                                        {body}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                    {notifications.length > showNotificationsQuantity && (
                        <div className="flex justify-center p-2 py-4 sm:px-4">
                            <div className="text-neutral-400 hover:text-brand-primary-light cursor-pointer text-2xs xs:text-xs sm:text-sm text-center">
                                <div onClick={() => setShowNotificationsQuantity(showNotificationsQuantity + 10)}>
                                    Show more
                                </div>
                            </div>
                        </div>
                    )}
                    {notifications.length < showNotificationsQuantity && (
                        <div className="text-neutral-400 text-2xs xs:text-xs sm:text-sm text-center p-2 py-4 sm:px-4">
                            <div>Showing all notifications</div>
                        </div>
                    )}
                </div>
            )}
            <Divider />
            <div
                className={cn('text-neutral-200 flex flex-wrap-reverse items-center p-2 sm:p-4 text-sm gap-2', {
                    ['justify-between']: !hideNotificationsTillNextLogin,
                    ['justify-end']: hideNotificationsTillNextLogin
                })}>
                {!hideNotificationsTillNextLogin && (
                    <div className="flex items-start gap-2">
                        <input
                            id="hide-notifications-checkbox"
                            type="checkbox"
                            style={{
                                height: 12,
                                width: 12
                            }}
                            className={cn('cursor-pointer mt-0.5 checked:accent-brand-primary-dark bg-neutral-400')}
                            checked={isChecked}
                            onChange={() => setIsChecked(!isChecked)}
                        />
                        <label
                            htmlFor="hide-notifications-checkbox"
                            className={cn('text-xs cursor-pointer', {
                                ['text-brand-primary-light']: isChecked,
                                ['text-neutral-200']: !isChecked
                            })}>
                            <span>Hide notification pop-ups until next login</span>
                            <br />
                            <span className="text-2xs text-neutral-400">
                                Notifications are still available by clicking the bell icon
                            </span>
                        </label>
                    </div>
                )}
                <div className="flex gap-2">
                    <button
                        className={cn('rounded-md p-2 px-4 bg-neutral-600 hover:bg-neutral-500', {
                            ['w-full']: !credentials
                        })}
                        onClick={() => {
                            handlers.close();
                            if (isChecked) dispatch(setHideNotificationsTillNextLogin(true));
                            else dispatch(setHideNotificationsTillNextLogin(false));
                        }}>
                        Dismiss
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default DesktopNotificationsModal;
