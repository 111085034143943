import { TimeInForceType } from '@/compiled_proto/com/celertech/orderrouting/api/enums/TimeInForceTypeProto';
import { OrderType } from '@/compiled_proto/com/celertech/positionmanager/api/enums/OrderTypeProto';
import { Side } from '@/compiled_proto/com/celertech/positionmanager/api/enums/SideProto';
import Divider from '@/components/common/Divider';
import Tooltip from '@/components/common/Tooltip';
import { getStopPrice } from '@/components/form/StopOrderForm';
import FormBody from '@/components/form/components/FormBody';
import FormCurrency from '@/components/form/components/FormCurrency';
import FormFooter from '@/components/form/components/FormFooter';
import FormOrderTypeSwitch from '@/components/form/components/FormOrderTypeSwitch';
import FormSubmit from '@/components/form/components/FormSubmit';
import Totals from '@/components/form/components/Totals';
import { StopOrderFormInput, StopOrderFormValues, stopOrderSchema } from '@/components/form/schema/stopOrderSchema';
import ExecutionInputController from '@/components/inputs/ExecutionInputController';
import RHFNumberInput from '@/components/inputs/RHFNumberInput';
import Select from '@/components/inputs/Select';
import { submitStopOrder } from '@/services/OrderService';
import { useAppSelector } from '@/state/hooks';
import { selectCredentials, selectCurrentAccount } from '@/state/reducers/authSlice';
import { useDidUpdate } from '@/utils/hooks/useDidUpdate';
import { invalidStopPriceMessage } from '@/utils/hooks/useOrderBook';
import { UseOrderExecutionTraderReturn } from '@/utils/hooks/useOrderExecutionTrader';
import { yupResolver } from '@hookform/resolvers/yup';
import BigNumber from 'bignumber.js';
import { useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { MdInfo } from 'react-icons/md';

const stopOrderInfo =
    'To limit risk of stops triggering on a temporary spread widening, Stops to Buy are triggered on the Bid, Stops to Sell are triggered on the Offer.';

const stopTypeOptions: { label: string; value: OrderType }[] = [
    { label: 'STOP MARKET', value: OrderType.STOP_MARKET },
    { label: 'STOP LIMIT', value: OrderType.STOP_LIMIT }
];

const durationOptions: { label: keyof typeof TimeInForceType; value: TimeInForceType }[] = [
    { label: 'DAY', value: TimeInForceType.DAY },
    { label: 'GTC', value: TimeInForceType.GTC }
];

const defaultValues: Partial<StopOrderFormInput> = {
    type: stopTypeOptions[0],
    stopPrice: null,
    quantity: null,
    duration: durationOptions[0],
    slippage: '0.00000'
};

interface StopOrderFormProps extends UseOrderExecutionTraderReturn {
    onClose?: () => void;
    closeOnSubmit?: boolean;
}

function StopOrderForm(props: StopOrderFormProps) {
    const { onClose, closeOnSubmit = false, ...orderExecutionProps } = props;
    const {
        ccy1,
        ccy2,
        sideState,
        currencyState,
        orderBook,
        instrument,
        activeCurrencyConfig,
        activeTicker,
        isCcy2Order
    } = orderExecutionProps;

    const credentials = useAppSelector(selectCredentials);
    const currentAccount = useAppSelector(selectCurrentAccount);

    const [side, setSide] = sideState;
    const [currency, setCurrency] = currencyState;

    const { formatPrice, pip_size, defaultSlippage, price_decimals } = instrument;
    const { asks, bids, bestAsk, bestBid, bestPrice } = orderBook;
    const { min_order_size, order_decimals, increments } = activeCurrencyConfig;

    const form = useForm<StopOrderFormInput>({
        defaultValues,
        mode: 'onChange',
        resolver: yupResolver(
            stopOrderSchema({
                priceOptions: {
                    side,
                    bestAsk,
                    bestBid,
                    formattedBestAsk: formatPrice(bestAsk),
                    formattedBestBid: formatPrice(bestBid),
                    ccy2Order: isCcy2Order
                },
                quantityOptions: {
                    min_order_size,
                    order_decimals,
                    currencyOut: currency,
                    currencyPair: activeTicker.show
                }
            })
        )
    });

    const {
        watch,
        reset,
        register,
        setValue,
        setError,
        clearErrors,
        handleSubmit,
        formState: { isSubmitting, isValid }
    } = form;

    const [stopType, stopPrice, slippage] = watch(['type.value', 'stopPrice', 'slippage']);

    const stopLimitPrice = useMemo(() => {
        const slip = BigNumber(+(slippage || 0)).dividedBy(Math.pow(10, pip_size || 0));
        const stop = +(stopPrice || 0);
        if (side === Side.BUY) return BigNumber(stop).plus(slip).toNumber();
        if (side === Side.SELL) return BigNumber(stop).minus(slip).toNumber();
        return stop;
    }, [stopPrice, slippage]);

    const onSubmit = async (data: StopOrderFormInput, e) => {
        const dataValidated = data as StopOrderFormValues;
        const slippage = dataValidated.slippage
            ? BigNumber(dataValidated.slippage)
                  .dividedBy(Math.pow(10, pip_size || 0))
                  .toString()
            : '0';
        if (dataValidated.quantity && credentials) {
            if (closeOnSubmit) onClose?.();
            await submitStopOrder(
                {
                    spotPrice: bestPrice,
                    securityId: activeTicker.celer,
                    currencyOut: currency,
                    quantity: dataValidated.quantity,
                    stopPrice: dataValidated.stopPrice,
                    timeInForce: dataValidated.duration.value as TimeInForceType,
                    orderType: dataValidated.type.value as OrderType,
                    limitPrice: stopLimitPrice,
                    side,
                    slippage
                },
                credentials,
                currentAccount
            );
            setValue('quantity', null, { shouldValidate: false });
        }
    };

    const onError = (errors) => console.error(errors);

    useEffect(() => {
        setValue('stopPrice', getStopPrice(bestBid, bestAsk, side, isCcy2Order, price_decimals), {
            shouldValidate: true
        });
    }, [side]);

    useEffect(() => {
        const isCcy1Buy = side === Side.BUY && !isCcy2Order;
        const isCcy2Buy = side === Side.BUY && isCcy2Order;
        const isCcy1Sell = side === Side.SELL && !isCcy2Order;
        const isCcy2Sell = side === Side.SELL && isCcy2Order;

        if (stopPrice && bestBid && bestAsk) {
            if ((isCcy1Buy || isCcy2Sell) && +stopPrice <= bestBid) {
                const message = invalidStopPriceMessage(side, formatPrice(bestBid), isCcy2Order);
                setError('stopPrice', { type: 'stopPriceConstraint', message });
            } else if ((isCcy2Buy || isCcy1Sell) && +stopPrice >= bestAsk) {
                const message = invalidStopPriceMessage(side, formatPrice(bestAsk), isCcy2Order);
                setError('stopPrice', { type: 'stopPriceConstraint', message });
            } else {
                clearErrors('stopPrice');
            }
        }
    }, [bestBid, bestAsk, isCcy2Order, side]);

    const resetDefault = useCallback(() => {
        // resets all fields, set limit price to default worst price
        const resetValues: typeof defaultValues = {
            ...defaultValues,
            stopPrice: getStopPrice(bestBid, bestAsk, side, isCcy2Order, price_decimals),
            slippage: defaultSlippage
        };
        reset(resetValues);
    }, [defaultValues, defaultSlippage, side, isCcy2Order, bestAsk, bestBid]);

    useDidUpdate(() => resetDefault(), [currency]);

    useEffect(() => {
        resetDefault();
    }, [activeTicker.celer]);

    return (
        <FormProvider {...form}>
            <form
                onSubmit={handleSubmit(onSubmit, onError)}
                className="relative flex flex-col h-full w-full text-neutral-200">
                <FormBody side={side} mode="Modal">
                    <FormOrderTypeSwitch initialValue={side} onChange={setSide} />
                    <FormCurrency {...props} mode="Trader" currency={currency} setCurrency={setCurrency} />

                    <div className="w-full relative">
                        <Tooltip content={stopOrderInfo} offset={6} className="max-w-sm">
                            <div className="absolute flex w-full justify-end right-0 top-0">
                                <MdInfo className="w-5 h-5" />
                            </div>
                        </Tooltip>
                        <ExecutionInputController name="type" label="Stop Type *" hasTooltipIcon>
                            <Select type="execution" options={stopTypeOptions} />
                        </ExecutionInputController>
                    </div>
                    <RHFNumberInput
                        label="Stop Price *"
                        placeholder="Stop Price"
                        {...register('stopPrice')}
                        step={BigNumber(1)
                            .dividedBy(Math.pow(10, pip_size || 0))
                            .toNumber()}
                    />
                    <RHFNumberInput
                        {...register('quantity')}
                        label="Quantity *"
                        placeholder="Quantity"
                        min={min_order_size}
                        step={increments}
                    />
                    <ExecutionInputController name="duration" label="Duration *">
                        <Select type="execution" options={durationOptions} />
                    </ExecutionInputController>
                    {stopType === OrderType.STOP_LIMIT && (
                        <RHFNumberInput
                            {...register('slippage')}
                            label="Slippage (pips)"
                            placeholder="Slippage (pips)"
                            min={defaultSlippage}
                            step={0.1}
                        />
                    )}
                    {stopType === OrderType.STOP_LIMIT && (
                        <Tooltip
                            content="The Stop Limit price is placed as the Stop Price +/- Slippage"
                            className="max-w-fit">
                            <RHFNumberInput
                                label="Stop Limit Price *"
                                value={stopLimitPrice}
                                placeholder="Stop Limit Price"
                                {...register('stopLimitPrice')}
                                disabled={true}
                            />
                        </Tooltip>
                    )}
                    <Totals
                        activePair={activeTicker}
                        side={side === Side.BUY ? Side.SELL : Side.BUY}
                        ccy1={ccy1}
                        ccy2={ccy2}
                        ccy2Order={isCcy2Order}
                        bids={bids}
                        asks={asks}
                        isTrader
                    />
                </FormBody>
                <Divider />
                <FormFooter mode="Modal">
                    <FormSubmit side={side} isValid={isValid} disabled={isSubmitting || !isValid} className="left-6" />
                </FormFooter>
            </form>
        </FormProvider>
    );
}

export default StopOrderForm;
