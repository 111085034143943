import cn from 'classnames';

export enum MarketListFilter {
    'All' = 0,
    'FX' = 1,
    'Crypto' = 2,
    'Index and Comm CFDs' = 3
}

interface TabFiltersMainProps {
    className?: string;
    children: React.ReactNode;
}

interface TabFiltersFilterProps {
    className?: string;
    filter: {
        label: any;
        value: any;
    };
    selectedFilter: any;
    setSelectedFilter: (value: any) => void;
}

const TabFilters = {
    Main: ({ className, children }: TabFiltersMainProps) => {
        return <div className={cn('flex min-h-fit overflow-x-auto', className)}>{children}</div>;
    },
    Filter: ({ className, filter, selectedFilter, setSelectedFilter }: TabFiltersFilterProps) => {
        return (
            <button
                key={filter.label}
                className={cn(
                    'p-1 px-2 text-sm font-medium leading-none h-[29px] text-neutral-200 focus:outline-none whitespace-nowrap',
                    {
                        'border-b-[1px] bg-brand-background-dark border-b-brand-border':
                            selectedFilter === filter.value,
                        'text-neutral-400 hover:bg-white/[0.12] hover:text-neutral-200': selectedFilter !== filter.value
                    },
                    className
                )}
                onClick={() => setSelectedFilter(filter.value)}>
                {filter.label}
            </button>
        );
    }
};

export default TabFilters;
