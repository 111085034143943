import { Fragment } from 'react';

interface FormFooterProps {
    children: React.ReactNode;
    mode?: 'Module' | 'Modal';
}

const FormFooter = (props: FormFooterProps) => {
    const { children, mode = 'Module' } = props;

    return (
        <Fragment>
            {mode === 'Module' && <div className="absolute bottom-0 p-2 w-full">{children}</div>}
            {mode === 'Modal' && <div className="p-2 sm:p-4 w-full">{children}</div>}
        </Fragment>
    );
};

export default FormFooter;
